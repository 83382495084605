import { classNames, Constants } from "@infotrack/infotrackgo.web.core/framework";
import { VerticalSearchForm, VehicleSearchFilters, ISearchFilters } from "@infotrack/infotrackgo.web.core/models";
import { FormikErrors, Formik, Form } from "formik";
import { MuiSelect } from "~/components/Formik/MuiInputs/MuiSelect";
import { FormikObserver } from "~/components/forms/FormikObserver";
import { StandardFormStore } from "~/stores/forms/standardFormStore";
import { vehicleSearchFormValidation } from "./vehicleSearchFormValidation";

// Styles
import styles from '../vehicle-search.module.scss';
import baseStyles from '../../Common/vertical-search.module.scss';

interface VehicleSearchFormProps {
    vehicleSearchFormStore: StandardFormStore<VerticalSearchForm<VehicleSearchFilters>>,
    setVerticalFilters: (filters: ISearchFilters) => void;
    formContainerClassName?: string;
}

export const VehicleSearchForm = ({ vehicleSearchFormStore, formContainerClassName }: VehicleSearchFormProps) => {
    const formOnChange = (value: VehicleSearchFilters, formikErrors: FormikErrors<VehicleSearchFilters>, isValid: boolean) => {
        const vechicleFilters = value;
        vehicleSearchFormStore.sync(
            isValid,
            { ...vehicleSearchFormStore.formValues!, filters: vechicleFilters },
            formikErrors
        );
    }

    return (
        <Formik
            initialErrors={vehicleSearchFormStore!.errors}
            initialTouched={vehicleSearchFormStore!.getIsTouched()}
            initialValues={vehicleSearchFormStore!.formValues?.filters ?? new VehicleSearchFilters()}
            validationSchema={vehicleSearchFormValidation}
            isInitialValid={vehicleSearchFormStore!.isValid}
            onSubmit={() => {}}
        >
            {({ values, errors, setErrors }) => (
                <>
                    <FormikObserver
                        value={values}
                        errors={errors}
                        onChange={formOnChange}
                        setErrors={setErrors}
                    />
                    <Form autoComplete="none" className={classNames(baseStyles.verticalSearchForm, formContainerClassName ?? '')}>
                        <MuiSelect
                            name="state"
                            id="vehicle-state-select"
                            label="State"
                            placeholder="Select state"
                            options={Constants.AUS_STATES}
                            className={classNames(baseStyles.verticalSearchFormField, styles.vehicleSearchFormField)}
                            required
                        />
                    </Form>
                </>
            )}
        </Formik>
    );
}
