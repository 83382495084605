import React from 'react';
import { InputProps, RenderInputComponentProps } from 'react-autosuggest';
import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import { getSearchBarPlaceholderText } from '~/components/SearchBars/HeaderSearchBar/SearchMenu/framework/getSearchBarPlaceholderText';
import { observer } from 'mobx-react-lite';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import { isOnSearchDomain } from '@infotrack/infotrackgo.web.core/framework/environment/isOnSearchDomain';
import { useItgRouterArgs } from '~/framework/hooks/useItgRouterArgs';
import { useSearchStore } from '~/stores/search/context/SearchProvider';
import TuneIcon from '@mui/icons-material/Tune';

// Styles
import styles from './search-bar-autocomplete-content.module.scss';

export interface AutocompleteInputProps {
    inputProps: RenderInputComponentProps;
    isSuggesting: boolean;
    leftContent: JSX.Element | null;
    buttonContent: JSX.Element | null;
    onClickInput: () => void;
    onSearchButtonClicked: () => void;
    className?: string;
    searchButtonContentClassname?: string;
    filterButtonContentClassname?: string;
    onFilterButtonClicked?: () => void;
    showFilterBtn?: boolean;
}

export const SEARCHBAR_ID = 'itg-smart-searchbar';
export const SEARCHBAR_INPUT_ID = 'searchbar-input';

// The component that <AutoComplete /> will render internally.
// this component should represent the actual content of the autocomplete.
export const SearchBarAutocompleteContent = observer(function AutocompleteInputImp(props: AutocompleteInputProps) {
    const { isSuggesting, buttonContent, leftContent } = props;
    const { selectedVertical } = useSearchStore();
    const path = isOnSearchDomain()
        ? useItgRouterArgs().pathName
        : window.location.pathname

    // If we are on the home page the default type is unknown.
    // If we are anywhere with a search header it is property.
    const defaultType = (
        path === '/' ?
        ServiceTypes.Unknown :
        ServiceTypes.Property
    );

    const selectedServiceType = (
        (selectedVertical && selectedVertical !== ServiceTypes.Unknown) ?
        selectedVertical :
        defaultType
    );

    const placeHolderText = getSearchBarPlaceholderText(selectedServiceType);

    // The autocomplete input component contains the potential left content, input and button content (right content)
    // this is so the drop down when it appears is inserted and fit perefctly bellow these elements
    // without any tricky logic required.
    return (
        <div id={SEARCHBAR_ID} className={classNames(props.className, styles.searchBarAutocompleteContent)}>
            {leftContent}
            <input
                {...props.inputProps}
                id={SEARCHBAR_INPUT_ID}
                type="text"
                placeholder={placeHolderText}
                onClick={props.onClickInput}
            />
            {props.showFilterBtn && (
                <button
                    id='search-filter-button'
                    className={classNames(styles.searchBarAutocompleteContentFilterBtn, props.filterButtonContentClassname)}
                    onClick={props.onFilterButtonClicked}
                >
                    <TuneIcon style={{ margin: '.4rem' }} />
                </button>
            )}
            <button
                id="search-button"
                onClick={props.onSearchButtonClicked}
                className={props.searchButtonContentClassname}
                // Override button styles when suggesting to disable any bottom right border radius.
                style={isSuggesting ? {  borderBottomRightRadius: '0rem' } : undefined}
            >
                {buttonContent}
            </button>
        </div>
    );
});
