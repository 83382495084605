import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSearchStore } from "~/stores/search/context/SearchProvider";
import Router from "next/router";
import { classNames, getCurrentSearchDomain, isOnSearchDomain, objectToQueryString } from "@infotrack/infotrackgo.web.core/framework";

// Styles
import styles from './vertical-search-btn.module.scss';

interface Props {
    disabled?: boolean;
    className?: string;
}

export const VerticalSearchBtn = observer(function VerticalSearchBtnFunc({ disabled, className }: Props) {
    const { buildItgRouterArgsQuery } = useSearchStore();

    const handleBtnClick = () => {
        const query = buildItgRouterArgsQuery();

        if (isOnSearchDomain()) {
            return Router.push({
                pathname: `/results`,
                query: query
            });
        }

        // Nav using location.href as react router will not be available in WP site.
        const goto = `${getCurrentSearchDomain()}/results${objectToQueryString({ ...query, referrer: 'WP' })}`;
        window.location.href = goto;
    }

    return (
        <Button
            id="vertical-search-btn"
            variant="contained"
            color="primary"
            className={classNames(styles.verticalSearchBtn, className ?? '')}
            disabled={disabled}
            onClick={handleBtnClick}
        >
            <Typography variant="body2" color="white">
                Search Now
            </Typography>
        </Button>
    );
})