import { Button } from '@mui/material';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import React from 'react';
import MuiDialog from '~/components/Dialog/MuiDialog';
import { State } from '@infotrack/infotrackgo.web.core/enums/State';
import { Constants } from '@infotrack/infotrackgo.web.core/framework/constants/constants';

// Styles
import styles from './statemodal.module.scss';

interface Props {
    open: boolean;
    name: string;
    onClose: (state?: State) => void;
}

export function StateModal({ open, name, onClose }: Props) {
    const handleCloseModal = (state: State) => {
        onClose(state);
    };

    const stateModalBody = (
        <div className={classNames(styles.itgStatemodalBody, 'state-modal')}>
            {Constants.AUS_STATES.map((state) => {
                return (
                    <Button
                        id={state}
                        key={`state-selection-${state}`}
                        variant="contained"
                        color="secondary"
                        onClick={() => handleCloseModal(state as State)}
                        className={classNames(styles.itgStatemodalButton, `state-modal-button__${state}`)}
                    >
                        {state}
                    </Button>
                );
            })}
        </div>
    );

    return (
        <MuiDialog
            open={open}
            className={styles.itgStatemodal}
            title={`Please select the state for your search: ${name}`}
            body={stateModalBody}
            onClose={onClose}
        />
    );
}
