import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';

export const handleMenuItems = (): (string | ServiceTypes)[] => {
    const titlesNotToDisplay = ['Form', 'Unknown'];
    let selectItems: (string | ServiceTypes)[] = Object.values(ServiceTypes).map((serviceType: string | ServiceTypes) => {
        if (titlesNotToDisplay.includes(`${serviceType}`) || typeof serviceType !== 'string') return '';
        return serviceType;
    });

    selectItems = selectItems.filter(item => item);
    return selectItems;
};