import * as React from 'react';
import {SvgIcon, SvgIconProps } from '@mui/material';


const ShoppingCartIcon: React.FunctionComponent<SvgIconProps> = (props: SvgIconProps) => {
  return (
      <SvgIcon
        viewBox="0 0 29 25"
        fill="none"
        {...props}
      >
          <path d="M7.86137 6.56028C8.58725 5.82994 9.40962 5.61513 10.3167 5.61621C15.2295 5.61943 20.1434 5.61621 25.0562 5.61943C26.7374 5.6205 27.9083 6.43247 28.2235 7.93074C28.335 8.46238 28.2653 9.07458 28.1238 9.60837C27.6048 11.5567 27.0248 13.4878 26.4608 15.4232C25.9976 17.0116 25.0412 17.7807 23.3771 17.7914C19.5537 17.815 15.7302 17.815 11.9078 17.7903C10.3403 17.7796 9.35172 16.9601 8.91319 15.4457C7.71019 11.2935 6.49753 7.14348 5.31061 2.98699C5.19481 2.58101 5.02862 2.45857 4.6169 2.46931C3.47393 2.49938 2.3299 2.48005 1.18694 2.47897C1.06149 2.47897 0.936041 2.48542 0.811666 2.47575C0.298083 2.43494 0.00215618 2.16106 1.17855e-05 1.73145C-0.00213261 1.29539 0.288433 1.02044 0.808449 0.992517C1.12904 0.975333 1.45177 0.988221 1.77343 0.988221C3.00646 0.988221 4.23948 0.996814 5.47144 0.983925C5.99789 0.978555 6.29918 1.20947 6.44392 1.71319C6.90175 3.30812 7.37459 4.89876 7.86137 6.56028Z" fill="#333333"/>
          <path d="M23.0844 24.6137C21.7698 24.6244 20.6204 23.4838 20.6119 22.1606C20.6033 20.8492 21.7709 19.6828 23.0768 19.6968C24.3796 19.7107 25.5054 20.8299 25.5204 22.1252C25.5354 23.4365 24.3935 24.6029 23.0844 24.6137Z" fill="#333333"/>
          <path d="M15.0537 22.1477C15.0558 23.5203 13.9793 24.6148 12.6273 24.6137C11.301 24.6126 10.1848 23.4752 10.1934 22.1359C10.202 20.8277 11.3031 19.715 12.6101 19.6946C13.9386 19.6732 15.0515 20.7901 15.0537 22.1477Z" fill="#333333"/>
      </SvgIcon>
  );
};

 export default ShoppingCartIcon;