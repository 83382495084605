import { ServiceTypes } from "@infotrack/infotrackgo.web.core/enums";
import { CompanySearch } from "../VerticalSearch/CompanySearch/CompanySearch";
import { PropertySearch } from "../VerticalSearch/PropertySearch/PropertySearch";
import { VehicleSearch } from "../VerticalSearch/VehicleSearch/VehicleSearch";
import { VerticalSearchProps } from "../VerticalSearch/Common/VerticalSearchProps";
import { observer } from "mobx-react-lite";
import { useSearchStore } from "~/stores/search/context/SearchProvider";
import { PersonSearch } from "../VerticalSearch/PersonSearch/PersonSearch";

/**
 * Expose the correct SearchForm passing any common Props to the vertical search
 */
export const VerticalSearchFormSelector = observer(function VerticalSearchFormSelectorFunc(props: VerticalSearchProps) {
    const { selectedVertical } = useSearchStore();

    switch (selectedVertical) {
        case ServiceTypes.Property:
            return <PropertySearch {...props} />;
        case ServiceTypes.Company:
            return <CompanySearch {...props} />;
        case ServiceTypes.Person:
            return <PersonSearch {...props}/>;
        case ServiceTypes.Vehicle:
            return <VehicleSearch {...props}/>;
        default:
            return null;
    }
});