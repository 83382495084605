import React, { ReactNode } from 'react';

import styles from '../core.module.scss';

interface Props {
    children: ReactNode;
    className?: string;
}

// Any headers must implement the BaseHeader.
// It applies a common id.
export function BaseHeader(props: Props) {
    return (
        <>
            <header id="itg-header" className={props.className || ''}>
                {props.children}
            </header>
        </>
    );
}
