import { observer } from "mobx-react-lite";
import { HomeSearchBar } from "~/components/SearchBars/HomeSearchBar/HomeSearchBar";
import { useSearchStore } from "~/stores/search/context/SearchProvider";
import { VerticalSearchBtn } from "../Common/VerticalSearchButton/VerticalSearchBtn";
import { ServiceTypes } from "@infotrack/infotrackgo.web.core/enums";
import { VerticalSearchProps } from "../Common/VerticalSearchProps";
import { VehicleSearchForm } from "./Form/VehicleSearchForm";

// Styles
import baseStyles from '../Common/vertical-search.module.scss';

export const VehicleSearch = observer(function VehicleSearchFun(props: VerticalSearchProps) {
    const { getVerticalSearchForm, setVerticalFilters } = useSearchStore();
    const vehicleSearchFormStore = getVerticalSearchForm(ServiceTypes.Vehicle)!;

    const enableSearchBtn = () => Boolean(
        vehicleSearchFormStore?.formValues?.searchTerm
        && vehicleSearchFormStore?.formValues?.searchTerm.length > 0
        && vehicleSearchFormStore.isValid
    );
    
    if (props.showFiltersOnly) {
        return (
            <VehicleSearchForm
                vehicleSearchFormStore={vehicleSearchFormStore}
                setVerticalFilters={setVerticalFilters}
                formContainerClassName={props.formContainerClassName}
            />
        ); 
    }

    return (
        <>
            <div className={baseStyles.verticalSearch}>
                <HomeSearchBar
                    hideSearchBtn={true}
                    classNames={baseStyles.verticalSearchBar}
                />
                <VehicleSearchForm
                    vehicleSearchFormStore={vehicleSearchFormStore}
                    setVerticalFilters={setVerticalFilters}
                    formContainerClassName={props.formContainerClassName}
                />
                <VerticalSearchBtn disabled={!enableSearchBtn()} />
            </div>
        </>
    );
})