import { createContext, useContext } from "react";
import { SearchStore } from "../searchStore";

/**
 * Alllow us to have a separate store provider only for the search bar
 */
export const SearchContext = createContext(new SearchStore());

export const SearchProvider = ({ children }) => {
    const searchStore = new SearchStore();
    return (
        <SearchContext.Provider value={searchStore}>{children}</SearchContext.Provider>
    );
}

export const useSearchStore = () => useContext(SearchContext);