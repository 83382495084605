import { observer } from "mobx-react-lite";
import { HomeSearchBar } from "~/components/SearchBars/HomeSearchBar/HomeSearchBar";
import { useSearchStore } from "~/stores/search/context/SearchProvider";
import { VerticalSearchBtn } from "../Common/VerticalSearchButton/VerticalSearchBtn";
import { ClassificationLabels, ServiceTypes } from "@infotrack/infotrackgo.web.core/enums";
import { VerticalSearchProps } from "../Common/VerticalSearchProps";

// Styles
import baseStyles from '../Common/vertical-search.module.scss';

export const PropertySearch = observer(function PropertySearchFun(props: VerticalSearchProps) {
    const { getVerticalSearchForm } = useSearchStore();

    const enableSearchBtn = () => {
        const propertyClassifications = [
            ClassificationLabels.Property_Address,
            ClassificationLabels.Property_TitleReference,
            ClassificationLabels.Property_NswDepositedPlanReference
        ];

        const registry = getVerticalSearchForm(ServiceTypes.Property);
        return Boolean(registry?.formValues?.searchTerm &&
            registry?.formValues.searchTerm.length > 0 &&
            propertyClassifications.includes(registry?.formValues.classification.label)
        );
    }
    
    return (
        <div className={baseStyles.verticalSearch}>
            <HomeSearchBar
                hideSearchBtn={true}
                classNames={baseStyles.verticalSearchBar}
            />
            <VerticalSearchBtn disabled={!enableSearchBtn()} />
        </div>
    );
})