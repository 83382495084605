import { classNames } from "@infotrack/infotrackgo.web.core/framework";
import { VerticalSearchForm, CompanySearchFilters, ISearchFilters, RegistrationStatuses } from "@infotrack/infotrackgo.web.core/models";
import { FormikErrors, Formik, Form } from "formik";
import { MuiSelect } from "~/components/Formik/MuiInputs/MuiSelect";
import { FormikObserver } from "~/components/forms/FormikObserver";
import { StandardFormStore } from "~/stores/forms/standardFormStore";

// Styles
import styles from '../company-search.module.scss';
import baseStyles from '../../Common/vertical-search.module.scss';

interface CompanySearchFormProps {
    companySearchFormStore: StandardFormStore<VerticalSearchForm<CompanySearchFilters>>,
    setVerticalFilters: (filters: ISearchFilters) => void,
    formContainerClassName?: string;
}

// All possible registration statuses to select from.
const registrationStatuses: RegistrationStatuses[] = [
    RegistrationStatuses.Registered,
    RegistrationStatuses.Deregistered,
    RegistrationStatuses.All
];

export const CompanySearchForm = ({ companySearchFormStore, setVerticalFilters, formContainerClassName }: CompanySearchFormProps) => {
    const filterFormValues: CompanySearchFilters = companySearchFormStore!.formValues?.filters ?? new CompanySearchFilters({ registrationStatus: RegistrationStatuses.All });

    const formOnChange = (value: CompanySearchFilters, _1: FormikErrors<CompanySearchFilters>, _2: boolean) => {
        const companyFilters = new CompanySearchFilters({ registrationStatus: value.registrationStatus });
        setVerticalFilters(companyFilters);
    }

    return (
        <Formik
            initialErrors={companySearchFormStore!.errors}
            initialTouched={companySearchFormStore!.getIsTouched()}
            initialValues={filterFormValues}
            isInitialValid={companySearchFormStore!.isValid}
            onSubmit={() => {}}
        >
            {({ values, errors, setErrors }) => (
                <>
                    <FormikObserver
                        value={values}
                        errors={errors}
                        onChange={formOnChange}
                        setErrors={setErrors}
                    />
                    <Form
                        autoComplete="none"
                        className={classNames(baseStyles.verticalSearchForm, formContainerClassName ?? '')}
                    >
                        <MuiSelect
                            name="registrationStatus"
                            id="company-registration-status-select"
                            label="Registration Status"
                            placeholder="Select reason for search"
                            options={registrationStatuses}
                            className={classNames(baseStyles.verticalSearchFormField, styles.companySearchFormField)}
                            inputProps={{ classes: { root: styles.companySearchSelect } }}
                        />
                    </Form>
                </>
            )}
        </Formik>
    );
}