import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Fab } from '@mui/material';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';

// Styles
import styles from './itg-dialog.module.scss';

interface Props {
    open: boolean;
    title: string | ReactNode;
    subtitle?: string;
    className?: string; 
    paperClassName?: string; 
    body?: JSX.Element;
    onClose: () => void;
}

export default function MuiDialog({open, title, subtitle, className, paperClassName, body, onClose}: Props) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={classNames(styles.itgDialog, className)}
            classes={{paper: paperClassName ?? styles.itgDialogPaper}}
            disableScrollLock
        >
            <DialogTitle className={styles.itgDialogTitle}>
                <span className={styles.itgDialogTitleOffset}>{title}</span>
                <Button
                    onClick={onClose}
                    className={styles.itgDialogCloseButton}
                    aria-label="close-dialog"
                    variant="text"
                >
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent classes={{root: styles.itgDialogContentRoot}}>
                {subtitle && (
                    <DialogContentText className={styles.itgDialogSubtitle}>
                        {subtitle}
                    </DialogContentText>
                    )
                }
            {body}
            </DialogContent>
        </Dialog>
    );
};