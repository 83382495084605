import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { IconButton, InputAdornment, StandardTextFieldProps, TextField, TextFieldProps } from "@mui/material";
import { classNames } from "@infotrack/infotrackgo.web.core/framework";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useField } from "formik";

// Styles
import styles from './year-range-picker.module.scss';

interface Props extends StandardTextFieldProps {
    containerClassName?: string;
}

export const YearRangePicker = (props: Props) => {
    const [activeYear, setActiveYear] = useState<number | null>(null);
    const [showModal, setShowModal] = useState(false);
    const yearSelectionModalRef = useRef<HTMLDivElement>(null);
    const [field, meta, helper] = useField(props.name!);
    const { containerClassName, ...fieldProps } = props;

    // Opens the Year selection modal in the correct session if any year has been selected
    useEffect(() => {
        if (showModal && yearSelectionModalRef.current) {
            // Calculate the position of the selected year in the list
            const yearRange = field.value.split('-');
            if (!yearRange || yearRange.length === 0) return;
            
            const selectedYearElement = yearSelectionModalRef.current.querySelector(`[data-year="${activeYear}"]`);
            if (selectedYearElement instanceof HTMLElement) {
                // Scroll the modal container to ensure the selected year is visible
                yearSelectionModalRef.current.scrollTop = selectedYearElement.offsetTop - yearSelectionModalRef?.current.offsetTop;
            }
        }
    }, [showModal, activeYear]);
    
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleYearSelection = (year: number) => {
        setActiveYear(year);
        const yearRange = field.value.trim().split('-');
        const startYear = yearRange.length > 0 ? yearRange[0] : null;
        const endYear = yearRange.length > 1 ? yearRange[1] : null;
        let newInput = '';
        // Reset range if no start or selected year is above the previus range end
        if (!startYear || (endYear && +endYear >= year)) {
            newInput = `${year} - `;
            helper.setValue(newInput);(newInput);
        } else {
            newInput = `${startYear.trim()} - ${year}`;
            helper.setValue(newInput);(newInput);
            toggleModal();
        }
    };

    const isInBetween = (year: number, startYear?: number, endYear?: number) => Boolean(startYear && endYear && year > startYear && year < endYear);
    const isStart = (year: number, startYear?: number, endYear?: number) => Boolean(startYear && endYear && year === startYear);
    const isEnd = (year: number, endYear?: number) => Boolean(endYear && year === endYear);
    const isActive = (year: number, startYear?: number, endYear?: number) => Boolean(year === activeYear || (startYear && endYear && year >= startYear && year <= endYear));

    const getYearItemClassnames = (year: number) => {
        const yearRange = field.value.trim().split('-');
        const startYear = yearRange.length > 0 ? +yearRange[0] : undefined;
        const endYear = yearRange.length > 1 ? +yearRange[1] : undefined;

        return classNames(
            styles.yearPickerYearItem,
            {
                [styles.yearPickerYearItemActive]: isActive(year, startYear, endYear),
                [styles.yearPickerYearItemActiveStart]: isStart(year, startYear, endYear),
                [styles.yearPickerYearItemActiveBetween]: isInBetween(year, startYear, endYear),
                [styles.yearPickerYearItemActiveEnd]: isEnd(year, endYear)
            }
        )
    }

    const generateYears = () => {
        const years: ReactElement[] = [];
        // MARKETING RANGE REQUIREMENTS 1900 - 2010 looks good. :)
        for (let i = 1900; i <= 2010; i++) {
            years.push(
                <div
                    key={i}
                    className={getYearItemClassnames(i)}
                    onClick={() => handleYearSelection(i)}
                    data-year={i}
                >
                    {i}
                </div>
            );
        }
        return years;
    };

    const fieldOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input = e.target.value;
        const isDigit = new RegExp(/^[\d\s-]+$/); // Only accepts digits, hiphen or white-space
        if (input && (!isDigit.test(e.target.value) || e.target.value.length > 11)) return;
                    
        // if backspace, let the user clean the field
        if (field.value.length >= e.target.value.length) return helper.setValue(e.target.value);        

        const values = input.trim().split('-');

        // Auto insert hyphen as input mask
        if (input.length >= 4 && !field.value.includes('-')) {
            input = `${input.trim()} - `;
        }

        setActiveYear(+values[0]);
        helper.setValue(input);
        if (props.onChange) props.onChange(e);
    }
    
    return (
        <div className={classNames(styles.yearPicker, props.containerClassName ?? '')}>
            <TextField
                size="small"
                variant="outlined"
                margin="dense"
                {...fieldProps}
                onChange={fieldOnChange}
                className={classNames(styles.YearPickerInput, props.className)}
                InputProps={{
                    endAdornment:
                    (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle year range picker"
                                onClick={toggleModal}
                                edge="end"
                            >
                                <DateRangeIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                error={!!meta.error || props.error}
                helperText={meta.error ?? ''}
            />
             {showModal && (
                <div className={styles.yearPickerModal}>
                    <div className={styles.yearPickerList} ref={yearSelectionModalRef}>{generateYears()}</div>
                </div>
            )}            
        </div>
    );
}