import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import { CSSProperties } from 'react';
import { VerticalSearchFormSelector } from '~/components/SearchBox/VerticalSearchFormSelector/VerticalSearchFormSelector';

// Styles...
import styles from './search-bar-attached-filters-content.module.scss';

interface Props {
    className?: string;
    searchBarRect: DOMRect | null;
    customStyle?: CSSProperties;
}

// The actual container element rendered by <AutoComplete /> that contains the rendered results (injected via children).
// This effectively, is the dropdown.
// we pass in the rect of the autcomplete content itself so that we can set the same width here
// making this container appear as a perfect extension of the autocomplete element.
export function SearchBarAttachedFiltersContent(props: Props) {
    const { searchBarRect } = props;
    const style: CSSProperties = { ...props.customStyle, width: searchBarRect?.width ?? '100%' };

    return (
        <div
            className={classNames(styles.searchBarAttachedFilters, props.className ?? '')}
            // width and positioning ALWAYS match the search-bar.
            style={style}
            id="search-bar-attached-filters"
        >
            <VerticalSearchFormSelector                
                key={'search-bar-attached-filters-selector'}
                showFiltersOnly
                formContainerClassName={styles.searchBarAttachedFiltersFormContainer}
            />
        </div>        
    );
}
