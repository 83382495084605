import PolicyIcon from '@mui/icons-material/Policy';
import KeyIcon from '@mui/icons-material/Key';
import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import { FormTypes } from '@infotrack/infotrackgo.web.core/enums';
import React from 'react';
import LocationPinSvg from '~/public/icons/search/LocationPin';
import StoreFrontSvg from '~/public/icons/search/StoreFront';
import GoPersonSvg from '~/public/icons/search/GoPerson';
import CarLineSvg from '~/public/icons/search/CarLine';

interface SearchMenuIconProps {
    serviceType: string;
}

// Menu icons for the search menu on home page.
export function SearchMenuIcon(props: SearchMenuIconProps) {
    const { serviceType } = props;
    switch (serviceType) {
        case `${ServiceTypes[ServiceTypes.Property]}`:
            return <LocationPinSvg />;
        case `${ServiceTypes[ServiceTypes.Company]}`:
            return <StoreFrontSvg />;
        case `${ServiceTypes[ServiceTypes.Person]}`:
            return <GoPersonSvg />;
        case `${ServiceTypes[ServiceTypes.Vehicle]}`:
            return <CarLineSvg />
        case `${FormTypes[FormTypes.PoliceCheck]}`:
            return <PolicyIcon />;
        case `${FormTypes[FormTypes.Posbn]}`:
            return <KeyIcon />;
        default:
            return null;
    }
};