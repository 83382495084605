import { useRouter } from 'next/router';
import { ITrackingInformation } from '~/models/App/TrackingInformation';
import { useEffect, useState } from 'react';
import { ClassificationLabels } from '@infotrack/infotrackgo.web.core/enums/ClassificationLabels';
import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import { stringToJson } from '@infotrack/infotrackgo.web.core/framework';
import { ISearchFilters } from '@infotrack/infotrackgo.web.core/models';

export interface ItgRouterArgs {
    searchTerm: string;
    suggestionId: string;
    serviceType: ServiceTypes;
    selectedLabel?: ClassificationLabels;
    // Arguments that are part of the search itself
    searchArgs?: any;
    // Search Filters
    searchFilters?: ISearchFilters;
    trackingInformation: ITrackingInformation;
    pathName?: string;
    help?: boolean;
    referrer?: string;
    error?: string;
}

export const buildItgRouterArgs = (query: any) => {
    const newArgs: any = {
        searchTerm: query['s'] || '',
        serviceType: query['cat'],
        label: query['label'],
        help: Boolean(query['help']),
        searchArgs: query['args'],
        suggestionId: query['suggestId'],
        pathName: query['pathname'],
        referrer: query['referrer'],
        error: query['error'],
    };

    if (!newArgs.serviceType) newArgs.serviceType = 'Unknown';
    if (newArgs.serviceType) newArgs.serviceType = ServiceTypes[newArgs.serviceType];
    if (newArgs.label) newArgs.label = ClassificationLabels[newArgs.label];
    if (newArgs.searchArgs) newArgs.searchArgs = stringToJson(newArgs.searchArgs);
    return newArgs;
}

/**
 *  useItgRouterArgs is a hook for retrieving ITG search arguments from the
 *  current route. Very handy for the /results page.
 *
 *  this should eventually phase out all the InitialAppState stores in AppStore.
 */
export const useItgRouterArgs = (): ItgRouterArgs => {
    const router = useRouter();
    if (!router) return {} as ItgRouterArgs;
    const { query, pathname } = router;
    const [args, setArgs] = useState<ItgRouterArgs>(buildItgRouterArgs({ ...query, pathname }));

    useEffect(() => {
        setArgs(buildItgRouterArgs({ ...query, pathname }));
    }, [query]);

    return args;
}