import { observer } from "mobx-react-lite";
import { useSearchStore } from "~/stores/search/context/SearchProvider";
import { ServiceTypes } from "@infotrack/infotrackgo.web.core/enums";
import { VerticalSearchBtn } from "../Common/VerticalSearchButton/VerticalSearchBtn";
import { VerticalSearchProps } from "../Common/VerticalSearchProps";
import React from "react";
import { PersonSearchForm } from "./Form/PersonSearchForm";
import { HomeSearchBar } from "~/components/SearchBars/HomeSearchBar/HomeSearchBar";

// Styles
import baseStyles from '../Common/vertical-search.module.scss';

export const PersonSearch = observer(function PersonSearchFunc(props: VerticalSearchProps) {
    const { getVerticalSearchForm, setVerticalFilters } = useSearchStore();
    const personSearchFormStore = getVerticalSearchForm(ServiceTypes.Person)!;
    const serchTerm: string = personSearchFormStore.formValues?.searchTerm || '';
    const hasLastName = serchTerm.split(' ').filter(x => !!x).length > 1;

    const enableSearchBtn = () => Boolean(
        personSearchFormStore?.formValues?.searchTerm
        && personSearchFormStore?.formValues?.searchTerm.length > 0
        && hasLastName
        && (personSearchFormStore.getIsTouched() == null || personSearchFormStore.isValid)
    );

    if (props.showFiltersOnly) {
        return (
            <PersonSearchForm
                personSearchFormStore={personSearchFormStore}
                setVerticalFilters={setVerticalFilters}
                formContainerClassName={props.formContainerClassName}
            />
        );
    }

    return (
        <>
            <div className={baseStyles.verticalSearch}>
                <HomeSearchBar
                    hideSearchBtn={true}
                    classNames={baseStyles.verticalSearchBar}
                />
                <PersonSearchForm
                    personSearchFormStore={personSearchFormStore}
                    setVerticalFilters={setVerticalFilters}
                    formContainerClassName={props.formContainerClassName}
                />
                <VerticalSearchBtn disabled={!enableSearchBtn()} />
            </div>
        </>
    );
})