import { RenderSuggestionsContainerParams } from 'react-autosuggest';
import Image from 'next/image';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';

// Styles...
import styles from './search-bar-suggestion-container.module.scss';

interface Props {
    className: string;
    searchBarRect: DOMRect | null;
    containerProps: any;
    params: RenderSuggestionsContainerParams;
    children: any;
}

// The actual container element rendered by <AutoComplete /> that contains the rendered results (injected via children).
// This effectively, is the dropdown.
// we pass in the rect of the autcomplete content itself so that we can set the same width here
// making this container appear as a perfect extension of the autocomplete element.
export function SearchBarSuggestionContainer(props: Props) {
    const { children, containerProps, searchBarRect } = props;
    if (!children) return null;

    const className = classNames(props.className, styles.searchBarSuggestionContainer);

    const getPinnContainerToSearchbarStyles = () => {
        if (!searchBarRect) return null;
        return {
            position: 'absolute',
            width: searchBarRect.width
        };
    }
    return (
        <div
            {...containerProps}
            className={className}
            // So that the width and positioning ALWAYS match the autocomplete input no matter what.
            style={getPinnContainerToSearchbarStyles()}
            id="autosuggestion-menu"
        >
            {children}
            <div className={styles.searchBarSuggestionContainerPoweredByGoogle}>
                <img src="https://d30tso943ai3px.cloudfront.net/itg/icons/powered_by_google.png" alt="google search logo" width={80} height={10}/>
            </div>
        </div>
    );
}
