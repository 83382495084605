import { geolocation } from '@infotrack/infotrackgo.web.core/api';
import { Constants, logger } from '@infotrack/infotrackgo.web.core/framework';

/**
 * Get the users geo location, defaulting to sydney.
 */
export const getGeolocation = async (): Promise<google.maps.LatLng> => {
    logger.info('Getting users geo location');
    try {
        const location = await geolocation();
        let latitude = location.latitude;
        let longitude = location.longitude;
        return new google.maps.LatLng(
            latitude,
            longitude,
            true
        );
    } catch (error: any) {
        logger.error('Failed to get geo location, returning sydney location as default', { error })
        return new google.maps.LatLng(
            Constants.GOOGLE.SYDNEY_LAT,
            Constants.GOOGLE.SYDNEY_LNG,
            true
        );
    }
}