import { getAutocompleteAddresses } from '~/api/google/get-autocomplete-addresses';
import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import { ClassificationLabels } from '@infotrack/infotrackgo.web.core/enums/ClassificationLabels';
import { Address, Classification } from '@infotrack/infotrackgo.web.core/models';

export type AutoSuggestion<T = any> = {
    value: string;
    displayTitle: string;
    identifier?: string;
    serviceType: ServiceTypes;
    label: ClassificationLabels;
    data?: T;
};

type AddressAutoSuggestion = AutoSuggestion<Partial<Address>>;

export const AutoSuggester = async (classificationResponse?: Classification[], searchTerm?: string): Promise<AutoSuggestion[]> => {
    if (!searchTerm || classificationResponse === undefined || !classificationResponse.length) {
        return [{ displayTitle: 'No results found.', value: '', serviceType: ServiceTypes.Unknown, label: ClassificationLabels.Unknown }];
    }

    const firstClassificationResult = classificationResponse[0];

    // If the user is searching by address, which only appears in subtype
    if (firstClassificationResult.label === ClassificationLabels.Property_Address) {
        return propertyAutoSuggest(firstClassificationResult.serviceType, searchTerm);
    }

    // The rest of the searches
    if (firstClassificationResult.label) return GenericAutoSuggest(classificationResponse, searchTerm);

    return [
        {
            displayTitle: `An error occurred. Please try again later.`,
            value: searchTerm,
            serviceType: ServiceTypes.Unknown,
            label: ClassificationLabels.Unknown
        }
    ];
};

const propertyAutoSuggest = async (serviceType: ServiceTypes, searchTerm: string): Promise<AddressAutoSuggestion[]> => {
    // Go to google
    const googleResults = await getAutocompleteAddresses(searchTerm);
    const genericResults = googleResults.map((x): AddressAutoSuggestion => ({
        displayTitle: x.address,
        value: x.placeId ? x.address : '',
        identifier: x.placeId,
        data: x.address,
        serviceType,
        label: ClassificationLabels.Property_Address
    }));
    if (genericResults.length === 0) {
        // If no valid property auto suggestion.
        // Add the 'no addresses found indicator.
        genericResults.push({
            displayTitle: 'No addresses found, try removing unit numbers or special characters.',
            value: '',
            identifier: '',
            data: '',
            serviceType,
            label: ClassificationLabels.Property_Address
        });
    }

    return genericResults;
};

const getClassificationDisplayLabel = (label: ClassificationLabels) => {
    const labels = {
        [ClassificationLabels.Property_Address]: 'Address',
        [ClassificationLabels.Property_TitleReference]: 'Title Search',
        [ClassificationLabels.Property_NswDepositedPlanReference]: 'Plan',
        [ClassificationLabels.Company_Name]: 'Company',
        [ClassificationLabels.Company_Identifier]: 'ACN',
        [ClassificationLabels.Person_Name]: 'Person',
        [ClassificationLabels.Vehicle]: 'Vehicle',
    }

    return labels[label];
}

/**
 * Return a single item with the searchterm + subservicetype
 */
const GenericAutoSuggest = async (classifications: Classification[], searchTerm: string): Promise<AutoSuggestion[]> => {
    // Change the suggestion info given by the classifier because it's not enough to show the results of the address
    // For that reason, we have to get the information from Google to be able to show the map and results
    const changeAddressSuggestion = async () => {
        const addressSuggestion = await propertyAutoSuggest(ServiceTypes.Property, searchTerm);
        return classificationsResult.map(suggestion => {
            if (suggestion.label === ClassificationLabels.Property_Address && addressSuggestion.length > 0) {
                return {
                    ...addressSuggestion[0],
                    displayTitle: `Address: ${addressSuggestion[0].displayTitle}`,
                    value: addressSuggestion[0]?.value || searchTerm
                };
            }
            return suggestion;
        });
    };

    let classificationsResult = classifications.map((classification: Classification) => {
        let type = '';

        if (classification.label !== ClassificationLabels.Unknown) {
            type = `${getClassificationDisplayLabel(classification.label)}: `;
        }

        const suggestion: AutoSuggestion = {
            displayTitle: `${type}${searchTerm}`,
            value: searchTerm,
            serviceType: classification.serviceType,
            label: classification.label
        };

        return suggestion;
    });

    // If the user is selecting the Address from the classifier suggestions and not from the list of googleResults
    // we have to get the information for that address from Google
    // Ticket: https://infotrack.atlassian.net/jira/software/projects/ITG/boards/138?selectedIssue=ITG-431
    const hasAddressSuggest = classificationsResult.find(o => o.label === ClassificationLabels.Property_Address);
    if (hasAddressSuggest) {
        classificationsResult = await changeAddressSuggestion();
    }

    return classificationsResult;
};
