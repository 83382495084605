import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { SearchBar } from '../Common/SearchBar/SearchBar';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import { Typography } from '@mui/material';

// Styles
import styles from './home-search-bar.module.scss';

export interface SearchBarProps {
    classNames?: string;
    hideSearchBtn?: boolean;
}

export function HomeSearchBar(props: SearchBarProps) {
    const getAutocompleteClassnames = (isSuggesting: boolean) => {
        return classNames(
            styles.homeSearchBarAutocomplete,
            { [styles.homeSearchBarAutocompleteSuggesting]: isSuggesting }
        );
    };

    const getAutocompleteSuggestionContainerClassnames = () => {
        return styles.homeSearchBarAutocompleteSuggestionContainer;
    };

    return (
        <SearchBar
            uniqueId='itg-search-bar-home'
            className={classNames(styles.homeSearchBar, props.classNames)}
            getAutocompleteClassnames={getAutocompleteClassnames}
            getAutocompleteSuggestionContainerClassnames={getAutocompleteSuggestionContainerClassnames}
            searchButtonContentClassname={
                props.hideSearchBtn 
                ? styles.homeSearchBarAutocompleteButtonHide
                : styles.homeSearchBarAutocompleteButton
            }

            renderButtonContent={
                () => props.hideSearchBtn
                ? null
                : (
                        <>
                            <SearchIcon className={styles.homeSearchBarAutocompleteButtonIcon}/>
                            <Typography
                                color="white"
                                variant="body2"
                            >
                                <span className={styles.homeSearchBarAutocompleteButtonSearchText}>Search</span>
                            </Typography>
                        </>
                    )
            }
        />
    );
}