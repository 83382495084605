import { ClassificationLabels } from "@infotrack/infotrackgo.web.core/enums";
import { classNames } from "@infotrack/infotrackgo.web.core/framework";
import { VerticalSearchForm, PersonSearchFilters, ISearchFilters } from "@infotrack/infotrackgo.web.core/models";
import { FormikErrors, Formik, Form, FormikHelpers } from "formik";
import { StandardFormStore } from "~/stores/forms/standardFormStore";
import { personSearchFormValidation } from "./personSearchFormValidation";
import { FormikObserver } from "~/components/forms/FormikObserver";
import NoSsr from "@mui/material/NoSsr";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { YearRangePicker } from "~/components/YearRangePicker/YearRangePicker";

// Styles
import styles from './person-search-form.module.scss';
import baseStyles from '../../Common/vertical-search.module.scss';

interface PersonSearchFormProps {
    personSearchFormStore: StandardFormStore<VerticalSearchForm<PersonSearchFilters>>,
    setVerticalFilters: (filters: ISearchFilters) => void,
    formContainerClassName?: string;
}

/**
 * Custom Checkbox for Person Search Form
 */
const ExactNameMatchCheckbox = (props: { checked: boolean, handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) => {
    return (
        <div className={classNames(styles.personSearchFormCheckbox, styles.personSearchFormItem)}>
            <Checkbox
                name="matchExactNames"
                onChange={(e) => props.handleChange(e)}
                checked={props.checked}
            ></Checkbox>
            <Typography variant="body1">
                Exact Name Match
            </Typography>
        </div>
    );
}

export const PersonSearchForm = ({ personSearchFormStore, setVerticalFilters, formContainerClassName }: PersonSearchFormProps) => {
    if (typeof window === 'undefined') return null;
    const filterFormValues: PersonSearchFilters = personSearchFormStore!.formValues?.filters ?? new PersonSearchFilters();

    const formOnChange = async (value: PersonSearchFilters, errors: FormikErrors<PersonSearchFilters>, isValid: boolean) => {
        const yearRange = value.yearRangeInput?.split('-');
        const personFilters = new PersonSearchFilters({
            ...value,
            yearOfBirthFrom: yearRange && yearRange.length > 0 ? +yearRange[0].trim() : undefined,
            yearOfBirthTo: yearRange && yearRange.length > 1 ? +yearRange[1].trim() : undefined,
        });

        const personFormValues: VerticalSearchForm<PersonSearchFilters> = {
            ...personSearchFormStore!.formValues,
            filters: personFilters,
            classification: { label: ClassificationLabels.Person_Name }
        };

        personSearchFormStore?.sync(isValid, personFormValues, errors);
    }

    const formOnSubmit = (values: PersonSearchFilters, _: FormikHelpers<PersonSearchFilters>) => {
        const personFilters = new PersonSearchFilters({ ...values });
        setVerticalFilters(personFilters);
    }

    return (
        <NoSsr>
            <Formik
                initialErrors={personSearchFormStore!.errors}
                initialTouched={personSearchFormStore!.getIsTouched()}
                validationSchema={personSearchFormValidation}
                initialValues={filterFormValues}
                onSubmit={formOnSubmit}
                validateOnChange
            >
                {({ values, errors, setErrors, isValid, handleChange, setValues }) => (
                    <>
                        <FormikObserver
                            value={values}
                            errors={errors}
                            onChange={formOnChange}
                            setErrors={setErrors}
                        />
                        <Form
                            autoComplete="none"
                            className={classNames(baseStyles.verticalSearchForm, {
                                [styles.personSearchForm]: true,
                                [formContainerClassName ?? '']: !!formContainerClassName
                            })}
                        >
                            <YearRangePicker
                                id="year-range-picker-input"
                                name="yearRangeInput"
                                placeholder="Birth Year Range"
                                label="Birth Year Range"
                                value={values.yearRangeInput ?? ''}
                                className={classNames(styles.personSearchFormItemInput)}
                                containerClassName={classNames(styles.personSearchFormItem)}
                            />
                            <ExactNameMatchCheckbox checked={values.matchExactNames ?? false} handleChange={handleChange} />
                        </Form>
                    </>
                )}
            </Formik>
        </NoSsr>
    );
}