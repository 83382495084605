import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '~/stores/stores';
import { Badge, Link } from '@mui/material';
import ShoppingCartIcon from '../../public/icons/shopping-cart';
import { NoSsr } from '@infotrack/infotrackgo.web.core/components';

// Styles
import styles from './shoppingcart.module.scss';

function ShoppingIcon(props: { badgeCount: number }) {
    return (
        <Badge badgeContent={props.badgeCount}>
            <ShoppingCartIcon className={styles.itgShoppingCartIcon} />
        </Badge>
    );
}

export const ShoppingCart = observer(function ShoppingCart() {
    const stores = useStore();
    const cartStore = stores.userStore.cartStore;
    const { servicesToOrder : cartItems } = cartStore;

    return (
        <NoSsr>
            <div className={styles.itgShoppingCart}>
                {/*
                    Using an <a/> tag here because if we used next.js Link,
                    the checkout page would be included in the SEO page bundles.
                */}
                <Link href="/checkout" aria-label="Checkout link" id="shopping-cart">
                    <ShoppingIcon badgeCount={cartItems.length} />
                </Link>
            </div>
        </NoSsr>
    );
});