import { TextField, StandardTextFieldProps, MenuItem } from '@mui/material';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import { useField } from 'formik';
import React from 'react';

import styles from '~/components/Formik/MuiInputs/mui-input.module.scss';

export interface Props extends StandardTextFieldProps {
    optionDisplayKey?: string;
    optionValueKey?: string;
    options: any[];
}

export const MuiSelect = (props: Props) => {
    const [field, meta, helper] = useField(props.name!);
    const hasErrors = meta.touched && !!meta.error;

    const handleClassNames = () => {
      return classNames(props.className, styles.itgFormField);
    };

    return (
      <TextField
          className={handleClassNames()}
          size={props.size ?? 'small'}
          variant="outlined"
          margin="dense"
          select
          {...field}
          {...props}
          id={hasErrors ? 'outlined-error-helper-text' : 'outlined-basic'}
          error={hasErrors}
          helperText={hasErrors && meta.error}
      >
          {
              props.options.map((option) => {
                  const value = props.optionValueKey ? option[props.optionValueKey] : option
                  const display = props.optionDisplayKey ? option[props.optionDisplayKey] : option
                  return (
                      <MenuItem key={value} value={value}>
                          {display}
                      </MenuItem>
                  );
              })
          }
        </TextField>
    );
  };