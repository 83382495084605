import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import React from 'react'
import { RenderSuggestionParams } from 'react-autosuggest';
import { uuid } from '@infotrack/infotrackgo.web.core/framework/utils/uuid';
import { ClassificationIcon } from '../../ClassificationIcon/ClassificationIcon';
import { AutoSuggestion } from '~/framework/AutoSuggester';

// Styles...
import styles from './search-bar-suggestion-container-result.module.scss';

interface Props {
    suggestion: AutoSuggestion;
    params: RenderSuggestionParams;
}

// Each individual result item rendered by the <AutoComplete /> component.
// Styling should be pretty consistent across implementations so shouldn't need
// any classname props.
export function SearchBarSuggestionContainerResult(props: Props) {
    const { suggestion } = props;
    return (
        <div
            key={uuid()}
            className={classNames(styles.searchBarSuggestionContainerResult, 'search-bar-result')}
        >
            <div className={styles.searchBarSuggestionContainerResultIcon}>
                <ClassificationIcon serviceType={suggestion.serviceType} />
            </div>
            {/* search-bar-result-text so that cypress can identify these items. */}
            <div className={classNames(styles.searchBarSuggestionContainerResultText, 'search-bar-result-text')}>
                {suggestion.displayTitle}
            </div>
        </div>
    );
}
