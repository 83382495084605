import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import LocationPinIcon from '~/public/icons/search/LocationPin';
import StoreFrontIcon from '~/public/icons/search/StoreFront';
import GoPersonIcon from '~/public/icons/search/GoPerson';
import CarLineIcon from '~/public/icons/search/CarLine';
import OutlineSearchIcon from '~/public/icons/search/OutlineSearch';

const classificationIconMap = {
    [ServiceTypes.Property]: <LocationPinIcon />,
    [ServiceTypes.Company]: <StoreFrontIcon />,
    [ServiceTypes.Vehicle]: <CarLineIcon />,
    [ServiceTypes.Person]: <GoPersonIcon />,
    [ServiceTypes.Unknown]: <OutlineSearchIcon />
};

// A common icon component that takes a service type and displays the correct corresponding icon.
export function ClassificationIcon(props: { serviceType?: ServiceTypes }) {
    const { serviceType } = props;
    if (serviceType === undefined || !classificationIconMap[serviceType]) {
        return classificationIconMap[ServiceTypes.Unknown];
    }
    return classificationIconMap[serviceType] || null;
};
