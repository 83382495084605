import { Timings } from '@infotrack/infotrackgo.web.core/enums';
import { applyCache } from '@infotrack/infotrackgo.web.core/framework/caching/applyCache';
import { Constants } from '@infotrack/infotrackgo.web.core/framework/constants/constants';
import { Address } from '@infotrack/infotrackgo.web.core/models';
import { getGeolocation } from '../search/getGeolocation';

/**
 * Map an address string returned from google to an ITG Address instance.
 * Func will return null if the address string is not a valid street address.
 */
const addressObjectFromGoogleString = (addressString: string) => {
    if (!addressString) return null;
    const address = new Address();
    const parts = addressString.split(',');
    if (parts.length !== 3) return null;
    // All addresses should be in 3 parts no matter what.
    // Google does not seem to include postcodes in the address string.
    // E.g. ['8 Harrabrook', 'Five Dock NSW', 'Australia']
    // E.g. ['43a Rofe Street', 'Leichhardt NSW', 'Australia']
    const [streetPart, suburbPart, countryPart] = parts;
    const streetParts = streetPart.split(' ');
    // Ensure the street number is valid (contains a number and is not a street name).
    const validSteeetNumberRegex = /\d+/ig
    const streetNumber = streetParts[0];
    if (!streetNumber.match(validSteeetNumberRegex)) return null;

    const streetName = streetParts.slice(1).join(' ');
    const suburbParts = suburbPart.split(' ');
    const suburb = suburbParts.slice(0, suburbParts.length - 1).join(' ');
    const state = suburbParts[suburbParts.length - 1];
    address.country = countryPart.trim();
    address.state = state.trim();
    address.suburb = suburb.trim();
    address.street = streetName.trim();
    address.streetNumber = streetNumber.trim();
    address.address = addressString;
    return address;
}

/**
 *  Process the API response from google, this function acts as both a filter and a mapper.
 */
function processPredicitons(predictions: google.maps.places.AutocompletePrediction[]): Address[] {
    const retAddresses: Address[] = [];
    const enabledTerms = new Set(['street_address', 'premise', 'subpremise', 'route']);

    predictions.forEach((pred: any) => {
        const types: string[] = pred.types;
        if (!types.some((type) => enabledTerms.has(type))) return;
        const address = addressObjectFromGoogleString(pred.description);
        // If street address was not returned or the country is not Australia, do not add to the list.
        if (!address || address.country !== 'Australia') return;
        address.placeId = pred.place_id;
        retAddresses.push(address);
    });

    return retAddresses;
}

/**
 *  This function accepts a search string as a parameter and returns a list of addresses
 *  given what google could predict.
 */
export const getAutocompleteAddresses = async (inputString: string): Promise<Address[]> => {
    if (typeof window === 'undefined') return new Promise(res => res([]));
    // If the user typed in a unit number, remove it from the search string.
    // This is because google places API can not handle these address types very well.
    const unitTest = /(^\d+\/|^Unit\s\d+\s)/gi
    const input = inputString.replace(unitTest, '');

    if (!input) return [];

    // Remove the Unit of the address
    const autoComplete = new google.maps.places.AutocompleteService();

    // Get location of the user to set the lat and long of the google maps api to
    // priotise the area in which the user is searching
    const location = await getGeolocation();

    const autocompleteRequestObj = {
        input: input,
        bounds: new google.maps.LatLngBounds(location),
        types: ['geocode'],
        radius: Constants.GOOGLE.RADIUS_IN_METERS,
        location: location,
        componentRestrictions: {
            country: ['au']
        }
    };
    const autocompleteRequest: () => Promise<{ data: google.maps.places.AutocompletePrediction[] }> = () => (new Promise(async (resolve) => (
        autoComplete.getPlacePredictions(
        autocompleteRequestObj,
        (res) => resolve({ data: res }))
    )));
    return applyCache(
        autocompleteRequest,
        { requestName: `google-autocomplete-${input}-${location}`, expireAfter: Timings.TenMinutes, vars: autocompleteRequestObj }
    ).then(({ data: predictions }) => processPredicitons(predictions || []));
};
