import { observer } from "mobx-react-lite";
import { HomeSearchBar } from "~/components/SearchBars/HomeSearchBar/HomeSearchBar";
import { useSearchStore } from "~/stores/search/context/SearchProvider";
import { ServiceTypes } from "@infotrack/infotrackgo.web.core/enums";
import { VerticalSearchBtn } from "../Common/VerticalSearchButton/VerticalSearchBtn";
import { VerticalSearchProps } from "../Common/VerticalSearchProps";
import { CompanySearchForm } from "./Form/CompanySearchForm";

// Styles
import baseStyles from '../Common/vertical-search.module.scss';

export const CompanySearch = observer(function CompanySearchFunc(props: VerticalSearchProps) {
    const { setVerticalFilters, getVerticalSearchForm } = useSearchStore();
    const companySearchFormStore = getVerticalSearchForm(ServiceTypes.Company)!;
    // always sets the company form as valid as no validation is needed - only optional fields
    companySearchFormStore.sync(true, companySearchFormStore.formValues!, []);

    const enableSearchBtn = () => Boolean(companySearchFormStore?.formValues?.searchTerm && companySearchFormStore?.formValues?.searchTerm.length > 0);
    
    if (props.showFiltersOnly) {
        return (
            <CompanySearchForm
                companySearchFormStore={companySearchFormStore}
                setVerticalFilters={setVerticalFilters}
                formContainerClassName={props.formContainerClassName}
            />
        );
    }

    return (
        <>
            <div className={baseStyles.verticalSearch}>
                <HomeSearchBar
                    hideSearchBtn={true}
                    classNames={baseStyles.verticalSearchBar}
                />
                 <CompanySearchForm
                    companySearchFormStore={companySearchFormStore}
                    setVerticalFilters={setVerticalFilters}
                    formContainerClassName={props.formContainerClassName}
                />
                <VerticalSearchBtn disabled={!enableSearchBtn} />
            </div>
        </>
    );
})